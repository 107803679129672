<div *ngIf="searching" class="autoinput">
    <nb-option *ngFor="let item of sp()" [value]="item" (click)="selectItem(item)"
        (keydown.enter)="selectItem($event.target.value)">
       {{item}}
    </nb-option>
</div>
<div class="wrapper-editor">

    <div [attr.contenteditable]="contenteditable" class="lb-editor" id="lbeditor" #lbeditor
        placeholder="Enter text here..." (keyup)="typ($event)" [innerHTML]="content | sanitizeHtml"
        (input)="settings.counter = $event.target.innerText.length" (focus)="toggleHeight('150px')"
        (focusout)="isNew ? toggleHeight('50px') : toggleHeight('150px')" (paste)="onPaste($event)">
    </div>
    <div class="save">
        <p class="counter" *ngIf="settings.counter >= 1">{{settings.counter}} <span
                *ngIf="settings.counter >= 8000">/10.000</span></p>
        <button nbButton status="success" class="mb-2" *ngIf="editor && isNew" nbTooltip="shortcut: ctrl + enter"
            nbTooltipStatus="default" nbTooltipPlacement="bottom" (click)="saveNewLB()">
            <nb-icon icon="paper-plane-outline"></nb-icon>
        </button>
    </div>
</div>