import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'highlightHashtags'})
export class HighlightHashtagsPipe implements PipeTransform {
  transform(text: string): string {
    let textWithHighlightedHashtags;
    if (text.indexOf('#') !== -1) {
      textWithHighlightedHashtags = text + ' ';
      const matches = textWithHighlightedHashtags.match(/\s\#(?!#)\w+/);
      for(let i = 0; i < matches.length; i++){
        textWithHighlightedHashtags = textWithHighlightedHashtags.replace(matches[i], '<span class="h-tag">' + matches[i] + '</span>');
      }
    }
    return textWithHighlightedHashtags;
  }
}
