import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import { MENU_ITEMS } from '../pages/pages-menu';
import { ApiService } from './apiService.service';

@Injectable({
    providedIn: 'root'
})

export class LogBlockService {
    private _logBlocks = new BehaviorSubject(null);
    public logBlocks$ = this._logBlocks.asObservable();
    public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public skip = new BehaviorSubject(0);
    public take = new BehaviorSubject(25);
    public include_shared = new BehaviorSubject(true);
    public query_search = new BehaviorSubject('');
    public seach_tags = new BehaviorSubject([])


    private _menu: BehaviorSubject<any> = new BehaviorSubject<any>(MENU_ITEMS);
    menu$ = this._menu.asObservable();

    private _basics: BehaviorSubject<any> = new BehaviorSubject(null);
    public basics$ = this._basics.asObservable();

    constructor(private apiService: ApiService, private toastrService: NbToastrService) { }


    setLB(logblocks) {
        this._logBlocks.next(logblocks);
        this.isLoading(false)
    }
    updateLBStore(isNew = false, data) {
        let current_lb = this._logBlocks.getValue();
        isNew ? current_lb.push(data) : current_lb.unshift(...data);
        this.setLB(current_lb);

    }
    public isLoading(state: boolean): void { this.loading.next(state) }

    public setMenu(menu): void { this._menu.next(menu) }
    public setBasics(data): void { this._basics.next(data) }

    public getLBs(loadNext?) {
        this.isLoading(true);

        if (loadNext) this.skip.next(this.skip.getValue() + this.take.getValue());

        this.apiService.getLBs(' ' + this.query_search.getValue().replace('#', '&num;'),
            this.skip.getValue(),
            this.take.getValue(),
            this.include_shared.getValue(),
            this.seach_tags.getValue()
        ).subscribe({
            next: (res: any) => {
                loadNext ? this.updateLBStore(false, res.data) : this.setLB(res.data);
                this.isLoading(false);
            },
            error: error => {
                this.toastrService.danger('Error');
                this.isLoading(false);
            }

        })

    }

    public setInitialParams() {
        this.skip.next(0);
        this.take.next(25);
        this.query_search.next('');
        this.include_shared.next(true);
        this.seach_tags.next([])
    }
}
