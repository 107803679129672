<nb-card>
    <nb-card-header>
        Password
        <nb-icon icon="close-outline" style="float: 'right'" (click)="dialogRef.close(true)"></nb-icon>
    </nb-card-header>
    <nb-card-body class="d-flex">
            <nb-form-field>
                <input [type]="getInputType()"  (keydown.enter)="confirmSecretPassword()" nbInput [(ngModel)]="password"  pattern="[A-Za-z]{3}" autocomplete="new-password">
                <button nbSuffix nbButton ghost (click)="toggleShowPassword()">
                    <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'" pack="eva"
                        [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
                    </nb-icon>
                </button>
            </nb-form-field>
            <button nbButton status="success" class="ml-2"
                (click)="confirmSecretPassword()"
                [disabled]="(alert_password && (password == '' || null)) || loading"
                [nbSpinner]="loading"  nbSpinnerSize="small" nbSpinnerStatus="primary">
                {{alert_password ? 'Create' : 'Submit'}}
            </button>
    </nb-card-body>
</nb-card>
<nb-alert [accent]="password =='' ? 'danger':'warning'" *ngIf="alert_password">
   {{ password =='' ? 'Password is required and should not contain spaces!':'You do not have a secret password, please create one!'}}
   <p class="mt-3">It's important to note that we don't keep a record of your password!
      <br>  <b>Remember, if you ever forget your password, you'll also lose access to your secrets.</b>
    </p>
</nb-alert>