import { Component, OnInit } from '@angular/core';
import { TokenService } from './service/auth/token.service';
@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  constructor(private tokenService: TokenService) { }

  ngOnInit(): void {
    this.tokenService.getToken();
    window.addEventListener('beforeunload', () => {
        localStorage.removeItem('token');
      });
  }
}
