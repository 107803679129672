<div class="custom_tooltip">
    {{text}}
</div>
<div class="minifigure d-flex">
	 <div class="head"   [ngStyle]="{
         'width': Hwidth,
         'height': Hheight}"
         >
	  <div class="faces-wrap">
		<div class="faces">
		  <div class="face" [ngClass]="smileStatus">
			<div class="eye right"></div>
			<div class="eye left" [ngClass]="smileStatus == 'wink'?'eyewink':''"></div>
			<div class="mouth" [ngClass]="smileStatus"></div>
		  </div>
		</div>
	  </div>
	</div> 


  </div>
  
 
  