import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NewBoard } from '../interfaces/board.interface';

const production = true;
// API URLS
let apiUrl = production ? 'https://api1.logblocks.app/' : 'http://localhost:8000/';

export const dev = {
	auth: apiUrl,
	production: production,
}

@Injectable({
	providedIn: 'root',
})

export class ApiService {

	constructor(private http: HttpClient) { }

    /* **********AUTH****************  */
    public checkUsername(username: string) {
        return this.http.get(apiUrl + `auth/check/username/${username}`)
    }
    public checkEmail(email: string) {
        return this.http.get(apiUrl + `auth/check/email/${email}`)
    }
    login(data){
        return this.http.post(apiUrl + 'auth/login',data)
    }
    registration(data){
        return this.http.post(apiUrl + `auth/register`,data)
    }
    
    
    /* **********LOGBLOCKS****************  */
    getLBs(search_query, skip_end, take, include_shared,search_tags=[]){
        return this.http.get(apiUrl + `lb/search`,
            {
              params: {
                search_query:search_query,
                skip_end:skip_end,
                take:take,
                include_shared: include_shared,
                search_tags:search_tags,
              },
            });
    }
    getInfo(){
        return this.http.get(apiUrl + 'lb/info')
    }
    getUsers(name){
        return this.http.get(apiUrl + `lb/users/${name}`)
    }
    addNewLB(data){
        return this.http.post(apiUrl + 'lb/add',data)
    }
    updateLB(data){
        return this.http.post(apiUrl + 'lb/update',data)
    }
    openSecrets(id,pass){
        return this.http.get(apiUrl + `lb/id/${id}?decrypt_pass=${pass}`)
    }


    /* ********** SECRETS ****************  */

    public confirmSecretPassword(password: string) {
        return this.http.get(apiUrl + 'secrets/password/confirm/' + password );
    }
    changeSecretPassword(data){
        return this.http.post(apiUrl + `secrets/password/change`,data)
    }

    /* **********BOARDS****************  */
    boardsInfo(){
        return this.http.get(apiUrl + 'boards/info')
    }
    searchBoards(skip, take,query){
        return this.http.get(apiUrl + 'boards/search',
        {
            params: {
              query:query,
              skip_end:skip,
              take:take,
            },
          })
    }
    getBoard(id){
        return this.http.get(apiUrl + `boards/id/${id}`)
    }
    addBoard(data:NewBoard){
        return this.http.post(apiUrl + `boards/add`,data)
    }
    updateBoard(data){
        return this.http.post(apiUrl + `boards/update`,data)
    }
    /* **********PINS****************  */
    getPinsIfo(){
        return  this.http.get(apiUrl + 'pins/info')
    }
    searchPins(skip,take,query){
        return this.http.get(apiUrl + 'pins/search',
        {
            params: {
                skip_end:skip,
                take:take,
                search_query:query,
            },
          })
    }
    getPin(id){
        return this.http.get(apiUrl + `pins/id/${id}`)
    }
    addPin(data:NewBoard){
        return this.http.post(apiUrl + `pins/add`,data)
    }
    updatePin(data){
        return this.http.post(apiUrl + `pins/update`,data)
    }
}