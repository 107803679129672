export interface LogBlock {
    id:string|number,
    
        created_at?:string,
        content:string,
        tags?:Array<any>,
        attachments_count?:number,
        get_meta?:Array<any>,
        attachments?:Array<any>,
        secrets_count?:number,
        get_secrets?:Array<any>,
        secrets?:Array<any>,
        content_raw:string,
        post?:Array<any>,
        owners: Array<Owner>

}

interface Owner {
    id:number,
    name:string
}


export const new_lb = {
    id:'',
    content:'',
    tags:[],
    secrets:[],
    background:'white'
    
}
export interface updateLB{
    id:string|number,
    content:string,
    encrypt_password?:string
}