import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Icons } from '../../../icons';
import { NbIconLibraries, NbToastrService } from '@nebular/theme';
import { ApiService } from '../../../service/apiService.service';

@Component({
    selector: 'ngx-page-settings',
    templateUrl: './page-settings.component.html',
    styleUrls: ['./page-settings.component.scss']
})
export class PageSettingsComponent extends Icons implements OnInit {
    @Output() emitView = new EventEmitter();
    @Output() emitZoom = new EventEmitter()
    @Input() view_option: boolean = false;
    @Input() board_option: boolean = false;
    @Input() board_settings;

    public view = '../../../../assets/icons/row.svg';
    public view_step = 1;

    constructor(public iconLibraries: NbIconLibraries,
                private apiService: ApiService,
                private toastrService: NbToastrService,) {
        super(iconLibraries)
    }

    ngOnInit(): void {
    }
    changeView() {
        if (this.view_step == 1) {
            this.view = '../../../../assets/icons/grid-4.svg';
            this.view_step = 2;
            this.emitView.emit(2)
        } else if (this.view_step == 2) {
            this.view = '../../../../assets/icons/grid-6.svg';
            this.view_step = 3;
            this.emitView.emit(3)
        } else {
            this.view = '../../../../assets/icons/row.svg';
            this.view_step = 1;
            this.emitView.emit(1)
        }
    }
    zoomMinus() {
        if (this.board_settings.zoom == 100) {
            this.board_settings.zoom = 75
        } else if (this.board_settings.zoom == 75) {
            this.board_settings.zoom = 50
        } else if (this.board_settings.zoom == 50) {
            this.board_settings.zoom = 25
        } else {
            return
        }
        this.emitZoom.emit(this.board_settings.zoom)
    }
    zoomPlus() {
        if (this.board_settings.zoom == 25) {
            this.board_settings.zoom = 50
        } else if (this.board_settings.zoom == 50) {
            this.board_settings.zoom = 75
        } else {
            this.board_settings.zoom = 100
        }
        this.emitZoom.emit(this.board_settings.zoom)
    }
    updateBoard(){
        console.log(this.board_settings);
        // this.board_settings.blocks[0].logblocks.push({
        //     id:'peNyTXRjfd7EyP83NB0D',
        //     name:'JIRA',
        //     b:'red',
        //     coord:{
        //         x:150,
        //         y:150
        //     }
        // })
        this.apiService.updateBoard(this.board_settings).subscribe({
            next:(res:any)=>{
                this.toastrService.success('',res.message)
            },
            error: err => this.toastrService.danger('Error')
        })
    }
}
