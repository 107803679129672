import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbComponentStatus, NbToastrService } from '@nebular/theme';
import { NbAuthService } from '@nebular/auth';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class TokenService {
    public tokenData = JSON.parse(localStorage.getItem('tokenData') || '{}');
    public role = new BehaviorSubject<number | null>(null);
    public user_id = new BehaviorSubject<number | null>(null);
    private tokenExpirationTimer: any;

    constructor(
        private nbService: NbAuthService,
        private router: Router,
        private toastService: NbToastrService,
    ) { }

    public getToken(): void {
        this.nbService.onTokenChange().subscribe(token => {
          this.nbService.isAuthenticated().subscribe(auth => {
            if (token || token.isValid())  {
              this.checkTokenExpiration(token['createdAt']);
            }
          })
        });
      };

    public checkTokenExpiration(created: number) {
        let now = new Date().getTime();
        let createdAt = new Date(created).getTime();
        let minutes = 60 * 1000;
    
        let expiresAt = createdAt + (60 * minutes);
        let expiresIn = expiresAt - now;
        let expired = expiresIn < 0;
        let refreshIn = expiresIn - (10 * minutes);
    
        if (expired) this.onLogout();
        else if (expiresIn < 10 * minutes) this.refreshToken();
        else this.setTimeout(refreshIn);
    }




    public refreshToken(): void {
        this.clearTimeout();
        this.nbService.isAuthenticated().subscribe({
           next: (auth) => {
                if (auth) {
                this.nbService.refreshToken('email').subscribe({
                  next:() => { },
                  error:() => this.onLogout()})
              } 
            },
            error:() => this.onLogout()});

    }

    public onLogout(): void {
        this.role.next(null);
        localStorage.removeItem('tokenData');
        localStorage.removeItem('auth_app_token');
        localStorage.removeItem('company_name');

        this.clearTimeout();
        this.router.navigateByUrl('/auth/login');
    }
    private setTimeout(ms: number): void {
        this.clearTimeout();
        this.tokenExpirationTimer = setTimeout(() => this.refreshToken(), ms);
      }
    
    private clearTimeout(): void {
        if (this.tokenExpirationTimer) clearTimeout(this.tokenExpirationTimer);
        this.tokenExpirationTimer = null;
    }

    showToast(title: string, message: string, status: NbComponentStatus = 'success') {
        this.toastService.show(title, message, { status });
    }
}
