import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { TokenService } from '../../service/auth/token.service';
import { tap } from 'rxjs';


// check is user is authenticated, so he can procees to pages folder
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private authService: NbAuthService, 
    private router: Router,
    private tokenService: TokenService,
  ) { }

  canActivate() {
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) this.router.navigate(['auth/login']);

          this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
            if (token.isValid()) {
              if (token.getPayload()['fs_cookie_id'] != '20231002a') {
                this.router.navigate(['auth/login']);
              }

              this.tokenService.user_id.next(token.getPayload()['user_id']);
            }
          });
        }),
      );
  }
}
