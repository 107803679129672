import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Recent',
    icon: 'clock-outline',
    home: true,
    link: '/l/recent',
  },

  {
    title: 'Boards',
    icon: 'grid-outline',
    link: '/l/boards',
  },
  

  {
    title: 'Pins',
    icon: 'hash-outline',
    link: '/l/pins',
  },

  {
    title: 'Custom Pins',
    // icon: 'hash-outline',
    link: '/pages/pins',
    group: true
  },

  {
    title: 'Custom Boards',
    // icon: 'hash-outline',
    link: '/pages/pins',
    group: true
  },


];
