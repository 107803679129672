import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LockStateService {
    public _lockStatus = new BehaviorSubject<boolean>(true);
    public lockStatus$ = this._lockStatus.asObservable();

    constructor() { }

    updateStatus(new_status:boolean){
        this._lockStatus.next(new_status);
    }
}
