import { Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ngx-smile',
  templateUrl: './smile.component.html',
  styleUrls: ['./smile.component.scss']
})
export class SmileComponent implements OnInit {
 @Input() public smileStatus: string = 'classic'
 @Input() public Hwidth?: string = '4.25em';
 @Input() public Hheight?: string = '3.25em';
//  Eye
 @Input() public Ewidth?: string = '.5em';
 @Input() public Eheight?: string = '.5em';
 @Input() public Etop?: string = '.8em';
 @Input() public Eright?: string = '1.25em';
//  Mouth
 @Input() public Mtop?: string = '1.8em';
 @Input() public Mwidth?: string = '1.5em';
 @Input() public Mheight?: string = '.5em';
 @Input() public Mborder?: string = '0.15em';
// *************Tooltip******************
@Input() public text = 'Welcome to LogBlocks'
@Input() public showT = 'none'
  constructor( private elementRef: ElementRef) { 
    
  }

  ngOnInit(): void {
      this.elementRef.nativeElement.style.setProperty('--eWidth', this.Ewidth);
      this.elementRef.nativeElement.style.setProperty('--eHeight', this.Eheight);
      this.elementRef.nativeElement.style.setProperty('--eTop', this.Etop);
      this.elementRef.nativeElement.style.setProperty('--eRight', this.Eright);
        // Mouth
      this.elementRef.nativeElement.style.setProperty('--mTop', this.Mtop);
      this.elementRef.nativeElement.style.setProperty('--mWidth', this.Mwidth);
      this.elementRef.nativeElement.style.setProperty('--mHeight', this.Mheight);
      this.elementRef.nativeElement.style.setProperty('--mBorder', this.Mborder);
    // *************Tooltip******************
    this.elementRef.nativeElement.style.setProperty('--showTooltip', this.showT);
  }
ngOnChanges(){
    if(this.showT){
        setTimeout(() => {
            this.elementRef.nativeElement.style.setProperty('--showTooltip', 'none');
        }, 2000);
    }
}
}
