import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { ApiService } from '../../../../service/apiService.service';
import { LocalService } from '../../../../service/local.service';
import { PasswordLBDialogComponent } from '../../password-lb-dialog/password-lb-dialog.component';
import { LockStateService } from '../../../../service/lockState.service';
import { updateLB } from '../../../../interfaces/lb.interface';
import { LogBlockService } from '../../../../service/logblock.service';
import { LBEditorComponent } from '../../lb-editor/lb-editor.component';

@Component({
    selector: 'ngx-edit-note',
    templateUrl: './edit-note.component.html',
    styleUrls: ['./edit-note.component.scss']
})
export class EditNoteComponent implements OnInit {

    constructor(protected dialogRef: NbDialogRef<EditNoteComponent>,
        private dialogService: NbDialogService,
        private apiService: ApiService,
        private localStore: LocalService,
        private lockStateService: LockStateService,
        private toastrService: NbToastrService,
        private logblockService: LogBlockService) { }
    @Input() note;
    @Input() mu_id;
    @Input() basics;
    @ViewChild('lbeditor') lbeditor: LBEditorComponent;

    public lock_status: boolean = true;
    public password = null;
    public editable_content;
    public users;
    public showUsers = false;

    public isOpen = false;
    public disableSave = false;
    public loading = false;
    public today = new Date()
    ngOnInit(): void {
        this.editable_content = this.note.content_raw;
    }

    // onContentChanged(event) {
    //     this.checkContent(event.text)
    // };

    toggleSecret() {

        const closeOnBackdropClick: boolean = false
        this.password = JSON.parse(this.localStore.getData('token'))?.value;
        if (!this.password) {
            this.dialogService.open(PasswordLBDialogComponent, { closeOnBackdropClick }).onClose.subscribe(
                res => {
                    if (res === false) {
                        this.lockStateService.updateStatus(res);
                        this.password = JSON.parse(this.localStore.getData('token'))?.value;
                        this.openSecrets()
                    } else {
                        this.lockStateService.updateStatus(true)
                    }
                }
            )
        } else if (this.password && this.isOpen) {
            this.lock_status = !this.lock_status;
            this.lock_status ? this.editable_content = this.note.content : this.editable_content = this.note.content_decrypted;
        }
        else {
            this.openSecrets()
        }

    }
    openSecrets() {
        this.apiService.openSecrets(this.note.id, this.password).subscribe(
            {
                next: (res: any) => {
                    this.lock_status = !this.lock_status;

                    this.note = res.data;
                    this.isOpen = true;
                    this.editable_content = this.note.content_decrypted

                },
                error: (e) => {
                    this.toastrService.danger(e.statusText, 'Error');
                },
            }
        )
    }

    close(emit?) {
        this.isOpen = false;
        this.dialogRef.close(emit);
    }
    async save() {
        this.lbeditor.updateEditor();
        this.editable_content = this.lbeditor.getContent()
        const regex_secret = /\?\?(.*?)\?\?/g;
        const secret_mark = this.editable_content.match(regex_secret);
        const secret_token = JSON.parse(this.localStore.getData('token'))?.value;

        let data: updateLB = {
            id: this.note.id,
            content: this.editable_content,
        }

        if (secret_mark && !secret_token) {
            this.dialogService.open(PasswordLBDialogComponent)
                .onClose.subscribe(
                    res => {
                        res === false ? this.lockStateService.updateStatus(res) : this.lockStateService.updateStatus(true);
                        data.encrypt_password = JSON.parse(this.localStore.getData('token'))?.value;
                        this.updateLB(data)
                    })
            //if token, add to data
        } else if (secret_mark && secret_token) {
            data.encrypt_password = secret_token;
            this.updateLB(data)
            // if content without secret
        } else { this.updateLB(data) }

    }
    updateLB(data: updateLB) {
        this.loading = true;
        this.apiService.updateLB(data).subscribe({
            next: (res: any) => {
                this.loading = false;

                if (res.message.includes('warning')) {
                    this.toastrService.warning(res.message, 'LogBlock Saved');
                    this.close()
                }
                else {
                    this.toastrService.success(res.message, "Success");
                    this.note = res.data
                    this.close(res.data)
                }

                // if (res.message == 'success') {
                //     this.note = res.data
                //     this.toastrService.success('LB was updated', 'Success');
                //     this.close(res.data)
                // } else {
                //     this.toastrService.danger('', 'Error')
                //     this.close()
                // }
            },
            error: (err) => {
                this.loading = false;
                this.toastrService.danger(err.error.detail, 'Error');
            }
        })
    }
    searchUser(name) {

        const str = name;
        const regex = /(^|\s)@(\S+)\s*$/g;
        const match = str.match(regex);

        if (match) {
            const lastItem = match[match.length - 1].slice(1); // get last '@'
            let result = lastItem.slice(1); //cut '@'-marck

            if (result[0] == '?' || result[0] == '@') { //check one more special mark '?' || '@' 
                const name = result.slice(1); //cut special mark
                if (name.trim() !== '') this.getUsers(result); //check if the string is not empty -> search user       
            } else {
                this.getUsers(result)
            }

        } else { this.users = null }
    }

    getUsers(name) {
        this.apiService.getUsers(name).subscribe(
            (res: any) => {
                res.data.length > 0 ? this.users = res.data : this.users = null
            }
        )
    }
    addOwner(user) {
        const regex = /@(\S+)\s*$/g;
        const match = this.editable_content.match(regex);
        if (match) {
            const lastMatch = match[0];
            let content_with_owner = this.editable_content.replace(lastMatch, `@${user.username}</p>`)
            this.note.content = content_with_owner;
            this.editable_content = content_with_owner;
            this.users = null

        }
    }
}
