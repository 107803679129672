import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbTagModule,
  NbCheckboxModule,
  NbCardModule,
  NbInputModule,
  NbFormFieldModule,
  NbAlertModule,
  NbSpinnerModule,
  NbListModule,
  NbPopoverModule,
  NbPopoverDirective,
  NbTooltipModule,
  NbAutocompleteModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import {
  FooterComponent,
  HeaderComponent,
} from './components';
import { CapitalizePipe } from './pipes';
import {
  OneColumnLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';
import { SmileComponent } from './components/smile/smile.component';
import { NoteComponent} from './components/note/note.component';
import { LBEditorComponent} from './components/lb-editor/lb-editor.component';
import { FormsModule } from '@angular/forms';
import { EditNoteComponent } from './components/note/edit-note/edit-note.component';
import { QuillModule } from 'ngx-quill';
import { SanitizeHtmlPipe } from './pipes/sanitizeHtml.pipe';
import { PasswordLBDialogComponent } from './components/password-lb-dialog/password-lb-dialog.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { RouterModule } from '@angular/router';
import { PageSettingsComponent } from './components/page-settings/page-settings.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HighlightHashtagsPipe } from './pipes/hashtag';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbIconModule,
  NbTagModule,
  NbCheckboxModule,
  NbCardModule,
  NbInputModule,
  NbFormFieldModule,
  NbAlertModule,
  NbSpinnerModule,
  NbTooltipModule,
  NbAutocompleteModule,
  NbPopoverModule
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  OneColumnLayoutComponent,
  SmileComponent,
  NoteComponent,
  LBEditorComponent,
  EditNoteComponent,
  PasswordLBDialogComponent,
  ComingSoonComponent,
  PageSettingsComponent
];
const PIPES = [
  CapitalizePipe,
  SanitizeHtmlPipe,
  HighlightHashtagsPipe
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ...NB_MODULES,
    QuillModule,
    RouterModule,
    InfiniteScrollModule
  ],
  exports: [ CommonModule, ...PIPES,  ...COMPONENTS,...NB_MODULES, QuillModule],
  declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [ DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME ],
        ).providers,
      ],
    };
  }
}
