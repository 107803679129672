import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../../../service/apiService.service';
import { map } from 'rxjs';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';
import { LogBlockService } from '../../../service/logblock.service';
import { updateLB } from '../../../interfaces/lb.interface';
import { EditNoteComponent } from '../note/edit-note/edit-note.component';
import { PasswordLBDialogComponent } from '../password-lb-dialog/password-lb-dialog.component';
import { LockStateService } from '../../../service/lockState.service';


@Component({
    selector: 'lb-editor',
    templateUrl: './lb-editor.component.html',
    styleUrls: ['./lb-editor.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LBEditorComponent implements OnInit {
    @Input() content: string = '';
    @Input() isNew = true;
    @Input() contenteditable: boolean;
    @Input() basics;

    content_temp = '';
    tagsSelect;
    search_val = '';
    searching = false;
    caret_position;
    editor = false;

    key_type = '';
  

    items = [];

    // regExp
    regexStr = '^[a-zA-Z0-9_#@! ]*$';
    shareRegex = /(?:^|\s)@(?!#)+[a-zA-Z0-9]+$/g
    share_getRegex = /(?:^|\s)@\?(?!#)+[a-zA-Z0-9]+$/g
    share_postRegex = /(?:^|\s)@@(?!#\?)+[a-zA-Z0-9]+$/g;
    tagRegex = /(?:^|\s)#(?!#\s\?\@)[a-zA-Z0-9]+$/g;
    secretRegex = /(?:^|\s)\?\?(?!\?)[\w ]*\?\?/g;


    @ViewChild('lbeditor') el: ElementRef;
    @ViewChild('autoInput') autoinput: ElementRef<HTMLDivElement>;
    @Input() tags;

    @HostListener('window:keydown.control.enter', ['$event'])
    saveNlb(event: KeyboardEvent) {
        event.preventDefault();
        this.saveNewLB()
    }

    settings = {
        counter: 0,
        maxLen: 10000,
        secret_maxLen: 1000,
        tag_maxLen: 50

    }

    utils = {
        special: {
            8: true,//backspace
            16: true,//shift
            17: true,//ctrl
            18: true,//alt
            46: true,//delete
        },
        navigational: {
            37: true,//leftArrow
            38: true,//upArrow
            39: true,//rightArrow
            40: true,//downArrow
        },
        isSpecial(e) {
            return typeof this.special[e.keyCode] !== 'undefined';
        },
        isNavigational(e) {
            return typeof this.navigational[e.keyCode] !== 'undefined';
        }
    }

    constructor(private apiService: ApiService,
        private toastrService: NbToastrService,
        private logblockService: LogBlockService,
        private elementRef: ElementRef,
        private dialogService: NbDialogService,
        private lockStateService: LockStateService,) { }


    ngOnInit() {
        this.isNew ? this.elementRef.nativeElement.style.setProperty('--min-height', '50px') : this.elementRef.nativeElement.style.setProperty('--min-height', '150px');
    }

    sp() {
        return this.items.filter(optionValue => optionValue.toLowerCase().includes(this.search_val.toLowerCase()));
    }
    search(val, type, append: boolean = false) {
        if (append) {
            if (['Shift', 'Tab', 'undefined', '#', '@', '?'].includes(val)) {
                // nothing
            }
            else if (['ArrowDown', 'ArrowUp', 'Enter'].includes(val)) {
                setTimeout(() => {
                    this.autoinput.nativeElement.dispatchEvent(new KeyboardEvent('keyup', { code: val }));
                }, 10);
            }
            else if (val == 'Backspace') {
                this.search_val = this.search_val.slice(0, -1);
            }
            else if (new RegExp(this.regexStr).test(val)) {
                this.search_val += val;
                this.items = [];
                if (type == 'share') {
                    this.getUsers(this.search_val)
                } else if (type == 'tags') {
                    this.items = this.basics.tags
                }
            }
        }
        else {
            this.search_val = val;
        }

    }

    async typ($event) {
        // console.log($event);
        
        let len = $event.target.innerText.length;
        let isSpecial = this.utils.isSpecial($event);
        let isNavigational = this.utils.isNavigational($event);

        if ((isSpecial && !this.searching) || isNavigational) {
            return true;
        }

        if (len >= this.settings.maxLen) {
            $event.preventDefault();
            this.toastrService.warning(`Please keep your messages concise. The maximum message length is ${this.settings.maxLen} characters.`, 'LogBlock limit')
            return false;
        }
        this.editor = true;
        // get caret cursor position
        let caret_position = this.getCaretPosition($event);
        this.caret_position = caret_position;

        // TAGS	
        if ($event.key == '#') {
            if (this.content_temp.slice(-1) == ';' || this.content_temp.slice(-1) == '') {
                console.log(2);
                this.key_type = 'tags'
                this.searching = true;
            }
            this.key_type = 'tags'
            this.searching = true;
        } else if ($event.key == '@') {
            this.key_type = 'share'
            this.searching = true;

        }
        else if ($event.key == ' ') {
            this.searching = false;
            this.search('', this.key_type, false);
            this.updateEditor();

        }
        else if (this.searching) {
            this.search($event.key, this.key_type, true);
        }
        else {
            // this.updateEditor();
        }
        this.content_temp = $event.target.innerHTML;

    }


    selectItem(val: string = '') {
        // console.log(this.search_val.length, val.length);
        
        if (this.search_val.length == 0 || val.length == 0) {
            return;
        }
        this.updateEditor(val, this.search_val);
        this.searching = false;
        this.search_val = '';
        this.items = [];

        return;
    }
   
    // prev1;
    // prev2;
    // cut_a = false;
    updateEditor(val?: string, search_str?: string) {

        const editor = document.getElementById('lbeditor');

        const sel = window.getSelection();
        const textSegments = this.getTextSegments(editor);
        const textContent = textSegments.map(({ text }) => text).join('');
        let anchorIndex = null;
        let focusIndex = null;
        let currentIndex = 0;
        textSegments.forEach(({ text, node }) => {
            if (node === sel.anchorNode) {
                anchorIndex = currentIndex + sel.anchorOffset;
            }
            if (node === sel.focusNode) {
                focusIndex = currentIndex + sel.focusOffset;
            }
            currentIndex += text.length;
        });

        if (val) {
            let text = this.renderTextSelect(editor.innerHTML, val, search_str);
            
            setTimeout(() => {  
                editor.innerHTML = this.renderText(text);
                this.moveCursorToEnd()
            },50);
        }
        else {
            editor.innerHTML = this.renderText(editor.innerHTML);
            this.moveCursorToEnd()
            // if (this.cut_a) {
            //     this.restoreSelection(anchorIndex - 1, focusIndex - 1)
            // } else {
            //     this.restoreSelection(anchorIndex, focusIndex);
            // }

            // this.prev1 = anchorIndex;
            // this.prev2 = focusIndex;
        }
        this.content_temp = this.el.nativeElement.innerHTML

    }

  
    renderTextSelect(text, val, search_str) {
        // console.log(text, '<-text/vall->', val);
        const tagRegex = /(?:^|\s)(#[^\s]+)/g;


        if (text.match(this.share_getRegex)) {
            let index = text.lastIndexOf('@?');
            const resultString = text.slice(0, index) +'@?' +val;
            return resultString
        } else if (text.match(this.share_postRegex)) {
            let index = text.lastIndexOf('@@');
            const resultString = text.slice(0, index) +'@@' +val;
            return resultString;
        } else if (text.match(this.shareRegex)) {
            let index = text.lastIndexOf('@');
            const resultString = text.slice(0, index) +'@' +val;
            return resultString;
        } else if (text.match(tagRegex)) {
            let index = text.lastIndexOf('#');
            const result = text.slice(0, index) +'#' +val;
            return result;
        }
    }
    renderText(text) {
        const regex = /\?\?.+?\?\?|\S+/g;
        let showToastr = false;
      
        
        const matches = text.match(regex).map((word)=>{
   
            if(this.secretRegex.test(word)){          
                if (word.length > this.settings.secret_maxLen) {
                    showToastr = true;
                    word = word.slice(0, this.settings.secret_maxLen-1);
                    return `<span class="h-secret">${word}??</span>`;
                } else {
                    return `<span class="h-secret">${word}</span>`;
                }
            }else{ return word}
        });
        
        return this.highlightTagShare(matches.join(' '))
    }
    highlightTagShare(content){
        // console.log(content.split(' '));
        
        const output = content.split(' ').map((word) => {
            
            if (this.tagRegex.test(word)) {
                if (word.length >= this.settings.tag_maxLen) this.toastrService.warning('', `Tag cannot be longer than ${this.settings.tag_maxLen} characters`)
                return `<span class="h-tag" contenteditable="false">${word.length >= this.settings.tag_maxLen ? word.slice(0, this.settings.tag_maxLen - 1) : word}</span>`;
            } 
            else if (this.shareRegex.test(word) || this.share_getRegex.test(word) || this.share_postRegex.test(word)) {
     
                // if (word.slice(1, word.lenght) == localStorage.getItem('username')) {
                //     this.search_val = '';
                //     this.searching = false;
                //     this.cut_a = true;
                //     this.toastrService.warning('', "You can't tag yourself")
                //     return word.slice(1, word.lenght)
                // } else {
                    return `<span class="h-share" contenteditable="false">${word}</span>`
                // }
            }else if(word == 'logblock'){
                return `<span class="h-special" contenteditable="false">${word}</span>`
            }
            else {
                return word;
            }
        })
        return output.join(' ');
    }
    getTextSegments(element) {
        const textSegments = [];
        Array.from(element.childNodes).forEach((node: any, i) => {
            switch (node.nodeType) {
                case Node.TEXT_NODE:

                    textSegments.push({ text: node.nodeValue, node });
                    break;

                case Node.ELEMENT_NODE:
                    textSegments.splice(textSegments.length, 0, ...(this.getTextSegments(node)));
                    break;

                default:
                    throw new Error(`Unexpected node type: ${node.nodeType}`);
            }
        });
        return textSegments;

    }
    // restoreSelection(absoluteAnchorIndex, absoluteFocusIndex) {
    //     const editor = document.getElementById('lbeditor');
    //     const sel = window.getSelection();
    //     const textSegments = this.getTextSegments(editor);

    //     let anchorNode = editor;
    //     let anchorIndex = 0;
    //     let focusNode = editor;
    //     let focusIndex = 0;
    //     let currentIndex = 0;
    //     textSegments.forEach(({ text, node }) => {
    //         const startIndexOfNode = currentIndex;
    //         const endIndexOfNode = startIndexOfNode + text.length;
    //         if (startIndexOfNode <= absoluteAnchorIndex && absoluteAnchorIndex <= endIndexOfNode) {
    //             anchorNode = node;
    //             anchorIndex = absoluteAnchorIndex - startIndexOfNode;
    //         }
    //         if (startIndexOfNode <= absoluteFocusIndex && absoluteFocusIndex <= endIndexOfNode) {
    //             focusNode = node;
    //             focusIndex = absoluteFocusIndex - startIndexOfNode;
    //         }
    //         currentIndex += text.length;
    //     });

    //     sel.setBaseAndExtent(anchorNode, anchorIndex, focusNode, focusIndex);
    // }
    moveCursorToEnd(){
        const editor = document.getElementById('lbeditor');
        const range = document.createRange();
        const selection = window.getSelection();
        range.setStart(editor, editor.childNodes.length);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
    }
    getCaretPosition($event) {
        if ($event.target.isContentEditable || document.designMode === "on") {
            $event.target.focus();
            const _range = document.getSelection().getRangeAt(0);
            if (!_range.collapsed) {
                return null;
            }
            const range = _range.cloneRange();
            const temp = document.createTextNode("\0");
            range.insertNode(temp);
            const caretposition = $event.target.innerText.indexOf("\0");
            temp.parentNode.removeChild(temp);
            return caretposition;
        }
    }

    getUsers(name) {
        this.apiService.getUsers(name).pipe(map((res: any) => res.data)).subscribe({
            next: (res: any) => {
                if (res.length != 0) {
                    let result = [];
                    res.forEach(member => {
                        if (member.username == localStorage.getItem('username')) {
                            return
                        } else {
                            result.push(member.username)
                        }
                    });
                    this.items = result
                } else {
                    this.items = [];
                }
            },
            error: err => this.toastrService.danger('', 'Server error')
        })
    }
    saveNewLB() {
        if (this.content_temp == '') {
            this.toastrService.warning("You can't save an empty logblock", 'Oooops')
        } else {
            // let data = this.logblockService.save(this.content_temp, 0);
            const regex_secret = /\?\?(.*?)\?\?/g;
            const secret_mark = this.content_temp.match(regex_secret);
            const secret_token = JSON.parse(localStorage.getItem('token'))?.value;


            let data: updateLB = {
                id: 0,
                content: this.content_temp,
            }
            //if no token, show password-modal
            if (secret_mark && !secret_token) {
                this.dialogService.open(PasswordLBDialogComponent)
                    .onClose.subscribe(
                        res => {
                            res === false ? this.lockStateService.updateStatus(res) : this.lockStateService.updateStatus(true)
                            data.encrypt_password = JSON.parse(localStorage.getItem('token'))?.value;
                            this.addNew(data)
                        })
                //if token, add to data
            } else if (secret_mark && secret_token) {
                data.encrypt_password = secret_token
                this.addNew(data)
                // if content without secret
            } else {
                this.addNew(data)
            }

        }

    }
    addNew(data: updateLB) {

        this.logblockService.isLoading(true);
        this.updateEditor();
        this.apiService.addNewLB(data).subscribe({
            next: (res: any) => {
                this.logblockService.isLoading(false);

                if (res.message.includes('warning')) {
                    this.toastrService.warning(res.message, 'LogBlock Saved');
                }
                else {
                    this.toastrService.success(res.message, "Success");
                    this.el.nativeElement.innerHTML = '';
                    this.logblockService.updateLBStore(true, res.data);
                }
            },
            error: (err) => {
                this.logblockService.isLoading(false);
                this.toastrService.danger(err.error.detail, 'Error');
            }
        }
        )
    }
    getContent() {
        if (this.content_temp = ' ') {
            return this.el.nativeElement.innerHTML
        } else return this.content_temp;
    }
    toggleHeight(height) {
        this.elementRef.nativeElement.style.setProperty('--min-height', height);
    }
    onPaste(e) {
        e.preventDefault();
        const clipboardData = e.clipboardData;
        const pastedText = clipboardData.getData('text');
        this.el.nativeElement.innerHTML = pastedText;
        this.updateEditor(pastedText);

        // Insert the value on cursor position
        // window.document.execCommand('insertText', false, pastedText);
    }
}


