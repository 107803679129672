<div class="wrapper" 
    #scrollBox
    infiniteScroll
    [infiniteScrollUpDistance]="1"
    [infiniteScrollThrottle]="0"
    [scrollWindow]="false"
    [alwaysCallback]="true"
    (scrolledUp)="onScrollUp()"
    (scrolled)="onScrollDown()">
    <div class="notes_container">
        <div class="box" *ngFor="let note of notes;let i = index" [style.background-color]="'#fefefe'">
            <div class="content-box">
                <div class="content" [innerHTML]="note.content_raw | sanitizeHtml">
                </div>
                <div class="rightTopBtn">
                    <nb-icon icon="edit-outline" (click)="openDialog(note,i)"></nb-icon>
                    
                </div>
            </div>

            <div class="footer-options" *ngIf="note.tags || note.owners">
                <div class="main">
                    <div class="d-flex align-items-center" *ngIf="note.tags&&note.tags.length !=0">
                        <nb-tag-list class="my-2">
                            <nb-tag *ngFor="let tag of note.tags" status="info" appearance="outline"
                                [text]="tag.name"></nb-tag>
                        </nb-tag-list>
                        <div class="ico">
                            <!-- <nb-icon icon="plus-outline" class="mx-2" (click)="showTags(i)"></nb-icon> -->
                            <ul class="tags-box" *ngIf="showTagList && note_index == i">
                                <li *ngFor="let tag of basics.tags" (click)="addTags(tag,note)">{{tag}}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="mt-2 d-flex align-items-center">
                        <div class="access_box2" *ngFor="let member of note.members">
                            <img *ngIf="member.avatar_img" [src]="member.avatar_img" alt="#" [nbTooltip]="member.name">
                            <span *ngIf="!member.avatar_img" 
                                  class="member-initials {{member.permission == 'owner' ? 'member-owner' : ''}}"
                                  [ngStyle]="{'background-color': member.avatar_color}"
                                  [nbTooltip]="member.name" >
                                {{member.name | slice:0:1}}
                            </span>
                        </div>
                        
                        <!-- <nb-icon icon="plus-outline" class="mx-2 ico"></nb-icon> -->
                    </div>

                </div>
                <div class="mt-1">
                    <p class="createdAt">{{((today | date: 'd-M-yy') == (note.created_at | date: 'd-M-yy')) ? 
                       'today: '+ (note.created_at | date: 'HH:mm') : 
                        note.created_at | date: 'dd-MMM-yyyy'}}
                    </p>
                </div>
            </div>

        </div>

    </div>
</div>
