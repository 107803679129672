import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit,Output,ViewChild,ViewEncapsulation } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { LogBlock, new_lb, updateLB } from '../../../interfaces/lb.interface';
import { EditNoteComponent } from './edit-note/edit-note.component';
import { ApiService } from '../../../service/apiService.service';
import { LogBlockService } from '../../../service/logblock.service';
import { TokenService } from '../../../service/auth/token.service';
import { Subject, Subscription, debounceTime, takeUntil } from 'rxjs';

@Component({
    selector: 'ngx-note',
    templateUrl: './note.component.html',
    styleUrls: ['./note.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NoteComponent implements OnInit {
    @Input() changeView;
    @Input() basics;
    @Output() emitEdit = new EventEmitter<boolean>();
    
    @ViewChild('scrollBox') private scrollBox! : ElementRef;
    
    public notes: Array<LogBlock | any>;
    public note_index;
    public showTagList = false;

    public today = new Date();
    public loading$ = this.logblockService.loading.asObservable();

    public editor = false;
    public isLoadingBlocks = false;
    public endOfList = false;
    public isLoadingOnScrollUp = false;

    public mu_id = this.tokenService.user_id.getValue();
    
    private destroy$: Subject<void> = new Subject<void>();
    constructor(
        private dialogService: NbDialogService,
        private apiService: ApiService,
        private toastrService: NbToastrService,
        private logblockService:LogBlockService,
        private elementRef: ElementRef,
        private tokenService: TokenService,
    ) { }

    ngOnInit(): void {  
        this.elementRef.nativeElement.style.setProperty('--lbWidth', '100%');
        this.logblockService.logBlocks$.pipe(takeUntil(this.destroy$)).subscribe(res => this.notes = res);
       
    }
    ngAfterViewChecked() {        
        if (!!this.scrollDown) this.scrollToBottom();
        else {
            if (this.scrollBox.nativeElement.scrollTop === 0) {
                this.scrollBox.nativeElement.scrollTop = this.scrollBox.nativeElement.scrollHeight - this.scrollBottom;
            }
        }    
    } 
    ngAfterContentInit() {
        this.scrollSub = this.scrollTerm$
            .pipe(debounceTime(400))
            .subscribe(() => this.loadNext());
    }
  

    ngOnChanges() {
        if (this.notes) {
            this.notes.forEach((note) => {
                note.members.forEach(member => {
                    if(member.id == this.mu_id && member.permission !== 'owner'){
                        note.permission = member.permission;
                    }
                });
            })
        }
        if(this.changeView == 1){
            this.elementRef.nativeElement.style.setProperty('--lbWidth', '100%');
        }else if(this.changeView == 2){
            this.elementRef.nativeElement.style.setProperty('--lbWidth', '45%');
        }else{
            this.elementRef.nativeElement.style.setProperty('--lbWidth', '20%');
        }
    }

    openDialog(note,index, closeOnBackdropClick = false) {
        this.emitEdit.emit(false)
        this.dialogService.open(EditNoteComponent, { closeOnBackdropClick, context: { note: note,mu_id:this.mu_id,basics:this.basics } }).onClose.subscribe(
            res => {
               if(res){
                this.notes[index] = res;
               }
               this.emitEdit.emit(true)
            }
        );    
    }

    showTags(index){
        this.note_index = index
        this.showTagList = !this.showTagList
    }
    
    addTags(tag,note) {
        if(note.content.match(`#${tag}`) == null){
            note.row_content = note.content;
            note.row_tags = [...note.tags];

            note.content = note.content + `<span class="hash"> #${tag} </span>`;
            note.tags.push({id:note.tags.length, name:tag});
            note.tags = [...new Map(note.tags.map((item) => [item["name"], item])).values()];
            this.updateNote(note)
        };
        
        this.showTagList = !this.showTagList
    }
    updateNote(note){
        const data = {
            id: note.id,
            content: note.content,
        }
        this.apiService.updateLB(data).subscribe({
           next: (res: any) => {
                if (res.message.includes('warning')) {
                    this.toastrService.warning(res.message, 'LogBlock Saved');
                }
                else {
                    this.toastrService.success(res.message, "Success");
                    note.content = res.data.content;
                    note.tags = res.data.tags;
                }

                // if (res.message == 'success') {
                //     note.content = res.data.content;
                //     note.tags = res.data.tags;
                //     this.toastrService.success('LB was updated', 'Success');
                // } else {
                //     this.toastrService.danger('', 'Error')
                // }
            },
            error:err=>{
                
                    this.toastrService.danger(err.error.detail, 'Error');
                    note.content = note.row_content;
                    note.tags = note.row_tags
                
            }

        }
    )
    }

    private scrollDown = true;
    private scrollBottom: number = 0;

    private scrollTerm$: Subject<void> = new Subject();
    private scrollSub!: Subscription;

    public onScrollDown(): void {
        this.scrollDown = false;
    }

    public onScrollUp(): void {
        if (this.scrollBox.nativeElement.scrollTop === 0) this.scrollBottom = this.scrollBox.nativeElement.scrollHeight + 120;
        this.scrollTerm$.next();
    }
    private scrollToBottom(): void {
        try {
            this.scrollBox.nativeElement.scrollTop = this.scrollBox.nativeElement.scrollHeight;
            this.scrollBox.nativeElement.style.scrollBehaviour = 'smooth';
        } catch (err) {
        }
    }

    loadNext(){
        if (!this.endOfList && !this.isLoadingOnScrollUp && !this.isLoadingBlocks){
            this.logblockService.getLBs(true)          
        }
    }
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

