import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
 import { catchError } from 'rxjs/operators';
@Injectable()
export class AppIDHttpInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | never> {

    let id: string = localStorage.getItem('mu_id')
    
    let copy_request:any
    if (id) {
      copy_request = request.clone({
        setHeaders: {
            Lbmu: id
        }
      });
    } else {
      copy_request = request.clone();
    }

    return next.handle(copy_request).pipe(
      catchError((error) => {
        return this.errorHandler(request, error);
      }));
  }

  errorHandler(request: HttpRequest<any>, error: any): Observable<never> {
    if (error instanceof HttpErrorResponse
      ) {
      
    }
    return throwError(() => error);
  }
  
}
