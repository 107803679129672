import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NbDialogService, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { map, takeUntil } from 'rxjs/operators';
import { Subject, Subscription, interval } from 'rxjs';
import { PasswordLBDialogComponent } from '../password-lb-dialog/password-lb-dialog.component';
import { LocalService } from '../../../service/local.service';
import { LockStateService } from '../../../service/lockState.service';
import { ApiService } from '../../../service/apiService.service';
import { LogBlockService } from '../../../service/logblock.service';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
    @ViewChild('minutes', { static: true }) minutes: ElementRef;
    @ViewChild('seconds', { static: true }) seconds: ElementRef;

    private destroy$: Subject<void> = new Subject<void>();
    userPictureOnly: boolean = false;
    include_shared: boolean = true;
    user: string = this.localStore.getData('username');
    userMenu = [
        { title: 'My LB Account', icon: 'person-outline', link: '/l/profile' },
        { title: 'More accounts', icon: 'people-outline', link: '/l/accounts' },
        { title: 'Support / FAQ', icon: 'question-mark-outline', link: '/l/help' },
        { title: 'Log out', icon: 'lock-outline', link: '/auth/logout' }];


    // secrets
    public lock_status$ = this.lockStateService.lockStatus$
    public timer$: Subscription;
    public show_timer = false;
    public query_search:string = '';

    constructor(
        private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        private themeService: NbThemeService,
        private breakpointService: NbMediaBreakpointsService,
        private dialogService: NbDialogService,
        private localStore: LocalService,
        private lockStateService: LockStateService,
        private apiService:ApiService,
        private logBlockService:LogBlockService) {
    }

    ngOnInit() {
        const { xl } = this.breakpointService.getBreakpointsMap();
        this.themeService.onMediaQueryChange()
            .pipe(
                map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
                takeUntil(this.destroy$),
            )
            .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

        // Set lock status
        this.lockStateService.updateStatus(this.checkLockStatus())
        this.lock_status$.subscribe(locked => { !locked ? this.startCountdown() : this.stopCountdown(); })
    }

    startCountdown() {
        const itemStr = this.localStore.getData('token');
        const item = JSON.parse(itemStr);
        this.show_timer = true
        this.timer$ = interval(1000)
            .subscribe(() => {
                const currentTime = new Date().getTime();
                const timeDifference = item.expiry - currentTime;

                if (timeDifference <= 0) {
                    this.stopCountdown();
                    this.lockStateService.updateStatus(true)
                }

                const m = Math.floor((timeDifference / (1000 * 60)) % 60);
                const s = Math.floor((timeDifference / 1000) % 60);

                this.minutes.nativeElement.innerText = `${m.toString().padStart(2, '0')}`
                this.seconds.nativeElement.innerText = `${s.toString().padStart(2, '0')}`
            });
    }

    stopCountdown() {
        this.show_timer = false;
        this.minutes.nativeElement.innerText = '';
        this.seconds.nativeElement.innerText = '';
        if (this.timer$) {
            this.timer$.unsubscribe();
        };
        this.localStore.removeData('token');
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');

        return false;
    }

    navigateHome() {
        this.menuService.navigateHome();
        return false;
    }

    unlockSecrets(e) {
        let status = this.checkLockStatus();
        console.log(status);
        
        if (status) {
            e.preventDefault();
            const closeOnBackdropClick: boolean = false;
            this.dialogService.open(PasswordLBDialogComponent, { closeOnBackdropClick }).onClose.subscribe(
                res => {
                    console.log(res);
                    
                    res === false ? this.lockStateService.updateStatus(res) : this.lockStateService.updateStatus(true)
                }
            )
        } else {
            this.lockStateService.updateStatus(true);
            this.stopCountdown();
           console.log(this.lockStateService._lockStatus.getValue());
           
            
        }
    }

    checkLockStatus(): boolean {
        const itemStr = this.localStore.getData('token');
        const item = JSON.parse(itemStr);
        const now: any = new Date();
        if (!itemStr || (now.getTime() > item.expiry)) {
            return true
        } else {
            return false;
        }

    }
    getLBs(){
        this.logBlockService.query_search.next(this.query_search);
        this.logBlockService.getLBs();
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
