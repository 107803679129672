<section class="options" *ngIf="board_settings">
    <div class="options-view" *ngIf="view_option">
        <img [src]="view" (click)="changeView()">
    </div>
    <div class="options-board" *ngIf="board_option">
        <input type="text" nbInput fieldSize="tiny" placeholder="Board name" [(ngModel)]="board_settings.name">
        <p class="m-0">Zoom:
            <nb-icon icon="zoomMinus" pack="own-icons" (click)="zoomMinus()"></nb-icon>
            {{board_settings.zoom}}%
            <nb-icon icon="zoomPlus" pack="own-icons" (click)="zoomPlus()"></nb-icon>
        </p>
        <nb-select size="tiny" placeholder="Board types" [(selected)]="board_settings.type">
            <nb-option value="blocks">Blocks</nb-option>
            <nb-option value="rows">Rows</nb-option>
            <nb-option value="open">Open</nb-option>
        </nb-select>
        <button nbButton status="success" size="tiny" (click)="updateBoard()">Save</button>
    </div>
    <ngx-smile showT="block"></ngx-smile>
</section>