import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalService {
  constructor() { }

  public setData(key: string, value: string,ttl:number) {
    const now = new Date();
	const item = {
		value: value,
		expiry: now.setMinutes(now.getMinutes() + ttl)
	}
	localStorage.setItem(key, JSON.stringify(item));
  }

  public getData(key: string) {
    return localStorage.getItem(key)
  }
  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }
}
