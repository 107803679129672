import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { LocalService } from '../../../service/local.service';
import { ApiService } from '../../../service/apiService.service';

@Component({
    selector: 'ngx-password-lb-dialog',
    templateUrl: './password-lb-dialog.component.html',
    styleUrls: ['./password-lb-dialog.component.scss']
})
export class PasswordLBDialogComponent implements OnInit {
    public showPassword = false;
    public unlocked_time = null;
    public password = null;
    public alert_password = false;
    public loading = false;

    constructor(protected dialogRef: NbDialogRef<PasswordLBDialogComponent>,
        private localStore: LocalService,
        private apiService: ApiService,
        private toastrService: NbToastrService) { }

    ngOnInit(): void {
        const has_secret_password = JSON.parse(this.localStore.getData('has_secret_password'));
        if(!has_secret_password){
            this.alert_password = true
        }
    }
    getInputType() {
        if (this.showPassword) {
            return 'text';
        }
        return 'password';
    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }

    public confirmSecretPassword(): void {
       const data =  this.password.split(" ").join("");
       if(data && data.length !=0){
        this.loading = true;
           this.apiService.confirmSecretPassword(data).subscribe({
               next:(res: any) => {
                   localStorage.setItem('has_secret_password','true')
                   this.localStore.setData('token', res.data, 5)
                   this.dialogRef.close(false)
                   this.alert_password = false;
                   this.loading = false
               },
               error:err=>{
                this.toastrService.danger(err.error.detail,'Error');
                this.loading = false
               }
            })
       }else{
        this.password = '';
       }
    }

}
